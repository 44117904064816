<template>
  <div class="content">
    <div class="top">
      <div class="among">
        <div class="userInfo">
          <div style="margin-right: 20px" @click="goHome">商城首页</div>
          <div style="margin-right: 4px" v-if="$store.state.userInfo.userName">{{ $store.state.userInfo.userName }}</div>
          <span style="margin-right: 20px">你好,欢迎光临cnc-x商城</span>
          <div style="margin-right: 20px" @click="shoppingCart">购物车</div>
          <div style="margin-right: 20px" @click="myOrder">我的订单</div>
          <div style="margin-right: 20px" @click="myCollect">收藏夹</div>
          <div style="margin-right: 20px" @click="helpCenter">帮助中心</div>
          <div style="margin-right: 20px" @click="logIn" v-if="!$store.state.userInfo.userId" >登录</div>
          <div style="margin-right: 20px" @click="logOut" v-else>退出</div>
        </div>
      </div>
    </div>

    <div class="bot">
      <div class="among">
        <div class="box">
          <div class="l" @click="shouYe">
            <img src="@/assets/logoSC.png" alt="出错了" />
          </div>
          <div class="z">
            <div class="inp_content">
              <div class="inp">
                <input type="text" v-model="inpVal" placeholder="请输入搜索内容"  @keyup.enter="handleEnterKey"/>
                <div class="btn" @click="sousuo">搜索</div>
              </div>
              <div class="text">
                <!-- <span class="sousuoKey cur" @click="sousKeyClick(i)" v-for="(i, index) in keyVallueSous" :key="index">{{ i
                }}</span> -->
                <span class="sousuoKey cur" @click="sousKeyClick('立式加工中心','010101')" >立式加工中心</span>
                <span class="sousuoKey cur" @click="sousKeyClick('卧式加工中心','010102')" >卧式加工中心</span>
                <span class="sousuoKey cur" @click="sousKeyClick('立式车床','010201')" >立式车床</span>
                <span class="sousuoKey cur" @click="sousKeyClick('液压剪板机','020102')" >液压剪板机</span>
                <span class="sousuoKey cur" @click="sousKeyClick('光纤激光切割机','030101')" >光纤激光切割机</span>
              </div>
            </div>
            <div class="gwc" @click="shoppingCart">
              <img src="@/assets/icon/gwc.png" alt="出错了" />
              <div class="text">我的购物车</div>
              <div class="tab" v-if="totalLength">{{ totalLength }}</div>
            </div>
          </div>
          <div class="r" style="background-color: #fff;">
            <div class="kf_l" >
              <!-- <img src="@/assets/icon/zxkf.png" alt="出错了" />
              <div class="text">
                <span style="color: #666">欢迎使用</span>
                <span style="color: #30b824; margin-top: 3px">在线客服</span>
              </div> -->
            </div>
            <div class="kf_r" style="float: right;">
              <img src="@/assets/icon/dh.png" alt="出错了" />
              <div class="text">
                <div style="color: #666">客服服务热线</div>
                <div style="color: #ff7c32; margin-top: 3px; font-weight: bold">
                  021-69160692
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "largeHead",
  data() {
    return {
      inpVal: "",
      input: "",
      // 购物车长度
      totalLength: 0,
      // 购物车
      shoppingCartList: '',
      keyVallueSous:[]
    };
  },
  props: {
    // menuShow: Boolean,
    tabArrShow: Number
  },
  computed: {
    currentPage() {
      return this.$store.state.currentPage; // 获取当前页面信息
    }
  },
  watch: {
    currentPage(newPage, oldPage) {
      this.menuShow = newPage
    },
    '$store.state.shoppingCartList'(newVal, oldVal) {
      this.shoppingCartList = this.$store.state.shoppingCartList
      this.totalLength = this.shoppingCartList.reduce((acc, obj) => {
        return acc + obj.mallOrderItemVos.length;
      }, 0);
    },
  },
  mounted() {
    // 从本地缓存中取出字符串并转换为数组
    this.keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous'));
    this.$store.commit('getUserInfoData')
    this.menuShow = this.$store.state.currentPage
    if (isNaN(this.tabArrShow)) return
    this.tabArr[this.tabArrShow].act = true
  },
  methods: {
    goHome(){
      this.$router.push("/hoveContent?actNum=0")
    },
    // 点击历史搜索
    sousKeyClick(typeName,id) {
      window.open(`#/search?id=${id}&typeName=${typeName}`, '_blank')
      return
      this.inpVal = i
      let keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous')) || [];
      let index = keyVallueSous.indexOf(this.inpVal)
      if (index !== -1) {
        keyVallueSous.splice(index, 1); 
        keyVallueSous.unshift(this.inpVal);
      } else {
        keyVallueSous.unshift(this.inpVal)
      }
      if (keyVallueSous.length > 5) {
        keyVallueSous.splice(5, keyVallueSous.length - 5);
      }
      // 将数组转换为字符串并存入本地缓存中
      localStorage.setItem('keyVallueSous', JSON.stringify(keyVallueSous));
      window.open(`#/search2?keyWords=${i}`, '_blank');
    },
    logIn(){
      localStorage.setItem('historyRoute', this.$route.fullPath);
      this.$router.push('/login')
    },
    logOut() {
      this.$confirm('确认退出登录吗?', '退出', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('historyRoute');
        this.$router.push('/login')
        this.$message.success('请重新登录')
      })
    },
    // 返回首页
    shouYe() {
      this.$router.push('/hoveContent?actNum=0')
    },
    // 跳转到我的订单页面
    myOrder() {
      window.open('#/myIndex/myOrder?actMyNum=0', '_blank');
    },
    // 跳转到帮助中心页面
    helpCenter() {
      window.open('#/helpCenter', '_blank');
    },
    // 跳转到我的收藏
    myCollect() {
      window.open('#/myIndex/myCollect?actMyNum=5', '_blank');
    },
    // 跳转购物车
    shoppingCart() {
      if(this.$route.path == "/myShoppingCart"){
        this.$router.push(`/myShoppingCart`)
        location.reload()
      } else {
        window.open('#/myShoppingCart', '_blank');
      }
    },
    arrTab() {
      this.menuShowClick = !this.menuShowClick
    },
    handleEnterKey(){
      this.sousuo()
    },
    // 点击搜索
    sousuo() {
      // this.$router.push("/search")
      if (!this.inpVal) return this.$message({
        message: "请输入关键词"
      })
      let keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous')) || [];
      let index = keyVallueSous.indexOf(this.inpVal)
      if (index !== -1) {
        keyVallueSous.splice(index, 1); 
        keyVallueSous.unshift(this.inpVal);
      } else {
        keyVallueSous.unshift(this.inpVal)
      }
      if (keyVallueSous.length > 5) {
        keyVallueSous.splice(5, keyVallueSous.length - 5);
      }
      // 将数组转换为字符串并存入本地缓存中
      localStorage.setItem('keyVallueSous', JSON.stringify(keyVallueSous));
      window.open(`#/search2?keyWords=${this.inpVal}`, '_blank');
    },
  },
};
</script>
  
<style lang="less" scoped>
.content {
  width: 100%;
  height: 130px;

  >.top {
    width: 100%;
    height: 30px;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;

    >.among {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: block !important;

      >.userInfo {
        width: auto;
        float: right;
        line-height: 30px;
        font-size: 12px;
        color: #333333;
        display: flex;

        >div {
          cursor: pointer;
          transition: all 0.3s;
        }

        >div:hover {
          color: #ff4242;
          transition: all 0.3s;
        }
      }
    }
  }

  >.bot {
    width: 100%;
    height: 100px;
    background-color: #fff;
    border-bottom: 3px solid #ff4242;

    >.among {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      padding-top: 24px;
      box-sizing: border-box;

      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      >.box {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;

        >.l {
          width: 240px;
          cursor: pointer;
        }

        >.z {
          display: flex;

          >.inp_content {
            width: 420px;
            height: 36px;
            border-radius: 4px;
            border: 2px solid #ff4242;
            box-sizing: border-box;

            .inp {
              width: 100%;
              height: 100%;
              display: flex;

              >input {
                width: 340px;
                height: 30px;
                background-color: #f9f9f9;
                border: 0px;
                border-radius: 4px 0 0 4px;
                padding-left: 10px;
                outline: none;
              }

              >.btn {
                width: 80px;
                background-color: #ff4242;
                text-align: center;
                line-height: 32px;
                color: #ffffff;
                cursor: pointer;
              }
            }

            >.text {
              width: 100%;
              font-size: 12px;
              color: #666;
              margin-top: 6px;

              >span {
                margin-right: 16px;
              }
            }
          }

          >.gwc {
            width: 100px;
            height: 36px;
            margin-left: 5px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;

            >img {
              width: 15px;
              margin-right: 2px;
            }

            >.text {
              font-size: 12px;
              color: #ff4242;
            }

            >.tab {
              width: auto;
              height: 12px;
              padding: 0 7px;
              box-sizing: border-box;
              border-radius: 16px;
              background-color: #ff4242;
              text-align: center;
              line-height: 14px;
              font-size: 10px;
              color: #fff;
              position: absolute;
              top: -5px;
              right: -5px;
            }
          }
        }

        >.r {
          width: 260px;
          height: 48px;
          background-color: #f5f5f5;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          box-sizing: border-box;

          >.kf_l {
            display: flex;

            >img {
              width: 32px;
              height: 32px;
              margin-right: 10px;
            }

            >.text {
              width: 48px;
              font-size: 12px;
            }
          }

          >.kf_r {
            display: flex;

            >img {
              width: 32px;
              height: 32px;
              margin-right: 10px;
            }

            >.text {
              width: 92px;
              font-size: 12px;
            }
          }
        }
      }

    }
  }
}
</style>