<template>
    <div class="home">
        <!-- 头部 -->
        <myHead></myHead>

        <el-dialog title="修改资料" :visible.sync="dialogVisible" width="55%" :before-close="handleClose">
            <div class="con">
                <div class="son">
                    <div class="title">头像</div>
                    <div class="imgg">
                        <el-upload class="avatar-uploader" action="https://api.cnc-x.cn//mall/uploads"
                            :data="{ type: '12' }" :show-file-list="false" :on-success="handleAvatarSuccess">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                </div>
                <div class="son">
                    <div class="title">昵称</div>
                    <div class="inp">
                        <el-input v-model="inputName" size="small" @blur="handleBlur"
                            :placeholder="userInfo.userName || userInfo.realName"></el-input>
                    </div>
                </div>
                <!-- <div class="son">
                    <div class="title">性别</div>
                    <div class="xuanZ">
                        <div class="so cur" v-for="(it, index) in genderArr" :key="index" @click="genderArrClick(index)">
                            <img v-if="it.act" src="@/assets/icon/xuanz.png" alt="">
                            <img v-else src="@/assets/icon/wxz.png" alt="">
                            <div class="text">{{ it.name }}</div>
                        </div>
                    </div>
                </div> -->
                <div class="son">
                    <div class="title">重设密码：</div>
                    <div class="inp">
                        <el-input type="password" v-model="input" size="small" placeholder="请设置登录密码"></el-input>
                    </div>
                    <div class="text">密码只能包括“数字、字母或_”，其他无效</div>
                </div>
                <div class="son">
                    <div class="title">确认密码：</div>
                    <div class="inp">
                        <el-input type="password" v-model="input2" size="small" placeholder="再次输入您设置的密码"></el-input>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false, input2 = '', input = ''">完成</el-button>
                <el-button type="primary" @click="resetPassword">修改密码</el-button>
            </span>
        </el-dialog>

        <div class="myContent">
            <div class="l_menu">
                <div class="xg cur" @click="diaClick">修改</div>
                <div class="imgtx">
                    <div class="img">
                        <img v-if="userInfo.userImage" :src="userInfo.userImage" alt="">
                        <img v-else src="@/assets/mrtx.png" alt="">
                    </div>
                </div>
                <div class="name" style="text-align: center;">{{ userInfo.userName || userInfo.realName }}</div>
                <div class="hy">普通会员</div>
                <div style="border-bottom: 1px solid #E0E0E0;margin-top: 12px;"></div>
                <div class="menuu">
                    <div class="s" @click="menClick(index)" v-for="(m, index) in menuArr" :key="index"
                        :class="[m.act ? 'actShow' : '']">
                        {{ m.name }}
                    </div>
                </div>
            </div>
            <div class="r">
                <transition name="fade">
                    <!-- 缓存组件 -->
                    <!-- <keep-alive :include="keepAliveComponents"> --> 
                        <!-- 右侧内容 -->
                        <router-view />
                    <!-- </keep-alive> -->
                </transition>
            </div>
        </div>



        <!-- 底部 -->
        <bottomContent></bottomContent>
    </div>
</template>

<script>
import { getModifyAvatar, getResetPassword } from "@/utils/api/myApi/index"
import myHead from "@/components/myHead.vue";
import bottomContent from "@/components/bottomContent.vue";
export default {
    name: 'myIndex',
    components: {
        myHead,
        bottomContent
    },
    data() {
        return {
            keepAliveComponents: ['myOrder'], // 指定需要保留状态的组件名称
            userInfo: {},
            genderArr: [
                {
                    name: '男',
                    act: true,
                },
                {
                    name: '女',
                    act: false,
                }
            ],
            genderArrIndex: 0, // 默认男
            input: '', // 密码
            input2: '', //确认密码
            inputName: '', // 昵称
            imageUrl: '', // 头像
            dialogVisible: false,
            userInfo: {},
            menuArr: [
                {
                    name: '我的订单',
                    act: true
                },
                {
                    name: '我的售后',
                    act: false
                },
                {
                    name: '我的优惠券',
                    act: false
                },
                {
                    name: '我的寻源',
                    act: false
                },
                {
                    name: '我的地址',
                    act: false
                },
                {
                    name: '我的收藏',
                    act: false
                },
                {
                    name: '我的关注',
                    act: false
                },
                {
                    name: '我的浏览',
                    act: false
                },
                {
                    name: '我的发票信息',
                    act: false
                },
            ]
        }
    },
    mounted() {
        // 拿vuex里的用户信息
        this.$store.commit("getUserInfoData")
        this.userInfo = this.$store.state.userInfo

        this.menuArr.map(p => p.act = false)
        this.menuArr[this.$route.query.actMyNum].act = true
        if (this.$route.query.modifyType) {
            this.diaClick()
        }
        // this.menClick(localStorage.getItem('myPageKey'))
        // this.menuArr.map(t => t.act = false)
        // this.menuArr[localStorage.getItem('myPageKey')].act = true
    },
    watch: {
        '$store.state.userInfo'(newVal, oldVal) {
            this.userInfo = this.$store.state.userInfo
        },
        // '$route.path': function (newPath, oldPath) {
        //     if (this.$route.query.actMyNum) {
        //     this.menuArr.map(p => p.act = false)
        //     this.menuArr[this.$route.query.actMyNum].act = true
        //     }
        // }
    },
    methods: {
        async resetPassword() {
            if (!this.input) return this.$message.error("密码不能为空")
            if (this.input != this.input2) return this.$message.error("两次密码不一致")
            const regex = /^[a-zA-Z0-9_]+$/;
            if (!regex.test(this.input)) return this.$message.error("非法格式")
            if (this.input.length < 6) return this.$message.error("密码长度最少6位")
            const res = await getResetPassword({
                telephone: this.userInfo.telephone,
                password: this.input
            })
            if (res.statusCode == 8201) {
                this.$message.success('密码重设成功，请重新登录')
                localStorage.removeItem('token');
                localStorage.removeItem('historyRoute');
                setTimeout(() => {
                    this.$router.push('/login')
                }, 2000)
            } else {
                this.$message.error("操作失败")
            }
        },
        diaClick() {
            this.dialogVisible = true
            this.imageUrl = this.userInfo.userImage
            this.genderArr.map(n => n.act = false)
            console.log(this.userInfo.sex)
            let ind = this.userInfo.sex == '男' ? 0 : 1
            this.genderArr[ind].act = true
        },
        async handleAvatarSuccess(rej, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            const res = await getModifyAvatar({
                userId: this.userInfo.userId,
                userImage: rej.data
            })
            if (res.statusCode == 8201) {
                this.$message.success('修改成功')
                this.dialogVisible = false
                this.$store.commit("getUserInfoData")
            } else {
                this.$message.error("操作失败")
            }
        },
        async genderArrClick(index) {
            this.genderArr.map(n => n.act = false)
            this.genderArr[index].act = true
            this.genderArrIndex = index
            const res = await getModifyAvatar({
                userId: this.userInfo.userId,
                sex: !index ? '男' : '女'
            })
            if (res.statusCode == 8201) {
                this.$message.success('修改成功')
                this.dialogVisible = false
                this.$store.commit("getUserInfoData")
            } else {
                this.$message.error("操作失败")
            }
        },
        async handleBlur() {
            const res = await getModifyAvatar({
                userId: this.userInfo.userId,
                userName: this.inputName
            })
            if (res.statusCode == 8201) {
                this.$message.success('修改成功')
                this.dialogVisible = false
                this.$store.commit("getUserInfoData")
            } else {
                this.$message.error("操作失败")
            }
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.input2 = ''
                    this.input = ''
                    done();
                })
                .catch(_ => { });
        },
        menClick(index) {
            this.menuArr.map(t => t.act = false)
            this.menuArr[index].act = true
            // 将激活数据存储在本地缓存中
            // localStorage.setItem('myPageKey', index);
            if (index == 0) return this.$router.push('/myIndex/myOrder?actMyNum=0')
            if (index == 1) return this.$router.push('/myIndex/myAfterSales?actMyNum=1')
            if (index == 2) return this.$router.push('/myIndex/myCoupon?actMyNum=2')
            if (index == 3) return this.$router.push('/myIndex/mySourcing?actMyNum=3')
            if (index == 4) return this.$router.push('/myIndex/myAddress?actMyNum=4')
            if (index == 5) return this.$router.push('/myIndex/myCollect?actMyNum=5')
            if (index == 6) return this.$router.push('/myIndex/myFocusOn?actMyNum=6')
            if (index == 7) return this.$router.push('/myIndex/myBrowsingHistory?actMyNum=7')
            if (index == 8) return this.$router.push('/myIndex/myInvoice?actMyNum=8')
        }
    }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 20px;
    color: #8c939d;
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    border: 1px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
}

.avatar-uploader-icon:hover {
    border-color: #409EFF;
    color: #409EFF;
}

.avatar {
    width: 72px;
    height: 72px;
    display: block;
    border-radius: 50%;
    object-fit: cover;
}

.con {
    width: 100%;
    padding: 0px 40px;
    box-sizing: border-box;

    >.son {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        color: #333333;
        font-size: 14px;

        >.title {
            font-weight: 500;
            margin-right: 20px;
        }

        >img {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            object-fit: cover;
        }

        >.text {
            color: #666;
        }

        >.inp {
            width: 220px;
            margin-right: 10px;
        }

        >.xuanZ {
            width: 150px;
            display: flex;
            align-items: center;

            >.so {
                width: 100%;
                display: flex;
                align-items: center;
                margin-right: 0px;

                >img {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    >.myContent {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        display: flex;

        >.l_menu {
            width: 130px;
            height: 530px;
            background-color: #fff;
            margin-right: 20px;
            padding: 5px 0;
            box-sizing: border-box;

            >.xg {
                width: 100%;
                height: auto;
                text-align: right;
                color: #999;
                font-size: 12px;
                padding-right: 10px;
                box-sizing: border-box;
            }

            >.imgtx {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
                margin-top: 10px;

                >.img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    overflow: hidden;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            >.name {
                font-size: 14px;
                color: #333;
                width: 100%;
                white-space: nowrap;
                /* 防止文本换行 */
                overflow: hidden;
                /* 隐藏超出部分的文本 */
                text-overflow: ellipsis;
                /* 用省略号代替超出部分的文本 */
                margin-top: 10px;
                padding: 0 5px;
                box-sizing: border-box;
            }

            >.hy {
                width: 60px;
                height: 20px;
                margin: 8px auto;
                border-radius: 12px;
                background-color: #FFD1D1;
                font-size: 12px;
                color: #FF4242;
                text-align: center;
                line-height: 20px;
            }

            >.menuu {
                width: 100%;
                height: auto;
                padding-left: 20px;
                box-sizing: border-box;
                margin-top: 13px;

                >.s {
                    height: 36px;
                    line-height: 36px;
                    color: #333333;
                    font-size: 14px;
                    transition: all .3s;
                    cursor: pointer;
                }

                >.s:hover {
                    color: #FF4242 !important;
                }
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    margin-left: -20px;
    transition: all 0.3s;
}


.actShow {
    color: #FF4242 !important;
}
</style>